:root {
  --primaryColor: rgba(2, 128, 19, 1);
  --backgroundColor: hsl(0, 0, 22);
  --textColor: rgba(255, 255, 255, 1);
  --neutral: rgba(255, 255, 255, 1);
  --iconColor: black;
  --iconBackgroundColor: white;

}

.footer {
  background-color: rgba(2, 128, 19, 1);

  color: var(--neutral);
  padding: 100px 20px 40px 20px;
}

.footerColumns {
  background: hsl(137, 45, 44, 1);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.footerColumn {
  display: flex;
  flex-direction: column;
}

.logo img {
  width: 100px;
  margin-bottom: 10px;
}

.footerColumn h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
  padding-left: 140px;
}

.footerColumn ul {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  list-style: none;
  padding-left: 140px;

}

.footerColumn ul li {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: color 0.3s ease;

}

.footerColumn ul li:hover {
  color: var(--textColor);
}

.footer p {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}

.footerColumn p {
  padding-left: 20px;
}

.socialIcons {
  display: flex;
  gap: 10px;
  padding-left: 20px;
}

.icon2 {
  color: black;
  background-color: white;
  border-radius: 50%;
  font-size: 15px;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 5px 5px;
  width: 25px;
  height: 25px;
}

.icon2:hover {
  color: var(--primaryColor);
}

/* Footer Bottom */
.footerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--iconColor);
  padding-top: 20px;
}


.footerBottom p {
  font-family: 'Poppins';
  padding-left: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;

}

.policies {
  display: flex;
  gap: 20px;
  padding-right: 50px;
}

.policies a {
  color: var(--neutral);
  text-decoration: none;
  transition: color 0.3s ease;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: right;

}

.policies a:hover {
  color: var(--textColor);
}

.policies span {
  color: var(--iconColor);
}