:root {
  --primaryColor: rgba(2, 128, 19, 1);
  --textColor: rgba(64, 63, 59, 1);
  --neutral: rgba(255, 255, 255, 1);
  --backgroundColor: hsl(0, 0%, 57%);
}

/* Container for the whole banner */
.banner {
  display: flex;
  align-items: center;
  /* Center content vertically */
  justify-content: space-between;
  /* Space between text and image */
  padding: 150px 100px 0px 100px;
  /* Adjust padding as needed */
  background-color: var(--neutral);
  /* Use your background color */
  /* margin-top: 80px; */
  /* Space to push banner below the navbar */

}

.banner .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* The text section of the banner */
.bannerText {
  flex: 1;
  max-width: 600px;
  font-family: Poppins, sans-serif;
  text-align: left;
  padding-top: 70px;
}

.bannerText h5 {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.2px;
  color: hsla(128, 97%, 25%, 1);
  padding-bottom: 15px;
}

.title {
  font-family: Poppins, sans-serif;
  font-size: 80px;
  font-weight: 600;
  line-height: 80px;
  text-align: left;
  padding-bottom: 15px;
  color: rgba(1, 45, 7, 1);
}

.title span {
  color: hsla(128, 97%, 25%, 1);
}

.subTitle {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 34.8px;
  text-align: left;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

/* Button on the right */
.banner-button {
  background-color: rgba(2, 128, 19, 1);
  color: var(--neutral);
  border: none;
  width: 203px;
  height: 48px;
  /* Ensure height is fixed */
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  transition: background-color 0.3s;
  opacity: 1;
  /* Set to 1 for visibility */
  z-index: 1001
}

.banner-button:hover {
  background-color: hsl(137, 45%, 60%);
  transition: .3s ease;
}

.mainImage {
  position: relative;
  padding-top: 50px;
}

.bannerImage {
  width: 616px;
  height: 574px;
  object-fit: cover;
  margin-top: 40px;
  object-position: center 30%;
  border-radius: 63px 0px 0px 0px;
  opacity: 1;
  display: block;

}