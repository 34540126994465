:root {
  --primaryColor: rgba(62, 162, 90, 1);
  --textColor: rgb(92, 92, 92);
  --neutral: rgb(255, 255, 255);
  --backgroundColor: rgba(255, 255, 255, 1);

}

.profile {
  width: 1240px;
  height: 740.02px;
  padding: 150px 0px 150px 0px;
  gap: 56.4px;
  opacity: 1;
  margin: auto;
  background-color: var(--neutral);
  color: rgba(1, 45, 7, 1);
  font-family: 'Poppins', sans-serif;
}

.profile .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-text h1 {
  color: rgba(1, 45, 7, 1);
  font-family: Poppins, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-bottom: 20px;
}


.top-frame {
  width: 100%;
  max-width: 600px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  opacity: 1;
  margin-bottom: 30px;
}

.user-name {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  background: white;
  color: rgba(0, 0, 0, 1);
}

.edit-button {
  background-color: transparent;
  border: none;
  color: blue;
  font-size: 18px;
  cursor: pointer;
}

.info-frame {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  display: flex;
  gap: 25px;
  border-radius: 9px 0 0 0;
  background: rgba(217, 217, 217, 1);
}

.profile-photo .photo-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(97, 97, 97, 1);
}

.right-info-frame {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
}

.location-frame {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  margin-top: 30px;
  /* background: rgba(97, 97, 97, 1); */
  background-color: rgba(246, 247, 249, 1);
}

.location-label {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: black;
  margin-bottom: 15px;
}

.location-details {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-left: 10px;
}

.location-icon {
  width: 40px;
  height: 30px;
  /* background: rgba(85, 86, 90, 1);
  border: 1px solid rgba(85, 86, 90, 1); */
  transform: rotate(-135deg);
}

.location-name {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  color: black;
  padding-left: 20px;
}

/* Personal Details Styles */
.personal-details-frame {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  margin-top: 30px;
  /* background: rgba(217, 217, 217, 1); */
  background-color: rgba(246, 247, 249, 1);
}

.personal-details-label {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: black;
  margin-bottom: 15px;
}

.details-row {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px; */
  display: flex;
  align-items: center;
  gap: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  /* margin: 20px; */
  border-bottom: 1px solid black;
}

.details-row .icon {
  color: black;
  font-size: 20px;
}

.detail-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}