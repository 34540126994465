/* Colors */
:root {
  --primaryColor: hsl(49, 76%, 45%);
  /*   --primaryColor: hsl(26, 93%, 50%); */
  --HoverColor: hsl(26, 90%, 42%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  --boxShadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);

  --boxShadow1: 0 2px 8px 2px rgba(202, 170, 28, 0.45);
}

.contact {
  background: var(--greyColor);
  padding: 40px 30px 40px 30px;
}

.contact .secContainer {
  gap: 1.5rem;
}

.contact .secContainer .contactTitle h1 {
  padding-bottom: .5rem;
  color: var(--primaryColor);
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.contact .secContainer .contactForm {
  margin-top: 3rem;
  background: var(--whiteColor);
  padding: 20px;
  font-size: 13px;
  font-weight: 300;
  color: var(--blackColor);
  border-radius: 1rem;
  /* uses to make the effect smooth */
  transition: 0.5s;

  overflow: hidden;
  border-radius: 1rem;
  box-shadow: var(--boxShadow);
}

.contact .secContainer .contactForm .formTitle h2 {
  font-size: 30px;
  font-weight: 500;
  color: var(--primaryColor);
  text-align: center;
  margin-bottom: 15px;
}

.contact .secContainer .contactForm .contactUsForm .buttonAndStatus {
  display: flex;
  flex-direction: row;
}

.contact .secContainer .contactForm .contactUsForm .btn1 {
  /* display: inline-block; */
  width: fit-content;
  background: var(--blackColor);
  padding: 14px 50px;
  border-radius: 16px;
  /* text-decoration: none; */
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  /* uses to make background color effect smooth */
  transition: 0.5s;
}

.contact .secContainer .contactForm:hover {
  box-shadow: var(--boxShadow1);
}

.contact .secContainer .contactForm .contactUsForm .btn:hover {

  background: var(--primaryColor);
}

.contact .secContainer .contactForm .contactUsForm h2 {
  font-size: 20px;
  font-weight: 500;
  color: var(--primaryColor);
  text-align: left;
  margin-left: 10px;
  margin-top: 10px;
}

.contact .secContainer .contactForm .contactUsForm form {
  width: 100%;
}

.contact .secContainer .contactForm .contactUsForm .contactInput {
  width: 100%;
  border: 2px solid #262626;
  outline: none;
  background: #262626;
  padding: 15px 0;
  margin: 15px 0;
  color: #fff;
  font-size: 18px;
  border-radius: 16px;
}

.contact .secContainer .contactForm .contactUsForm .contactInput:focus {
  /* 
  background: var(--primaryColor); */
  /* outline: var(--primaryColor); */

  /* outline: none !important; */
  border: 2px solid var(--primaryColor);
}

.contact .secContainer .contactForm .contactUsForm .buttonAndStatus {
  display: flex;
  flex-direction: row;
}

/* Media Queries =====================> */
@media screen and (min-width: 950px) {
  .contact .secContainer .contactForm {
    width: 50%;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }
}