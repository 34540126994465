.reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;

}

.reset-frame {
  width: 400px;
  height: 100%;
  padding: 20px;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.lock-image-wrapper {
  padding-top: 120px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.lock-image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background: rgba(2, 128, 19, 1);
}

.reset-heading {
  text-align: center;
  margin-bottom: 20px;
}

.reset-title {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: rgba(2, 128, 19, 1);
  margin-bottom: 20px;
}

.reset-subtitle {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(97, 103, 125, 1);
}

.input-wrapper {
  position: relative;
  width: 100%;
  /* Ensure it fills the parent container */
  height: 58px;
  margin-bottom: 16px;
}

.input-field {
  width: 100%;
  height: 100%;
  padding: 16px 40px 16px 16px;
  /* Space for the icon on the right */
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(177, 174, 182, 1);
  border-radius: 8px;
  /* Customize as per your design */
}

.input-field::placeholder {
  color: rgba(107, 107, 107, 1);
  /* Label placeholder color */
}

.input-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: rgba(64, 63, 59, 1);
  cursor: pointer;
}

.continue-btn {
  margin-top: 40px;
  width: 100%;
  padding: 16px;
  background: rgba(2, 128, 19, 1);
  color: white;
  font-family: 'Poppins', sans-serif;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}