:root {
  --primaryColor: rgba(2, 128, 19, 1);
  --backgroundColor: rgba(243, 243, 243, 1);
  --textColor: rgb(92, 92, 92);
  --neutral: hsl(0, 0, 100, 1);
}

.mostPopularSkills {
  background-color: rgba(243, 243, 243, 1);
  text-align: center;
  margin-top: 150px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 60px;
}

.mostPopularSkills h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
  padding-bottom: 30px;
}

.mostPopularSkills p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.2px;
  margin-bottom: 40px;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  border-bottom: 2px solid black;
  padding-bottom: 30px;
}

.tab {
  padding: 10px 20px;
  margin: 0 130px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: black;
  transition: background-color 0.3s ease;
}

.tab.active,
.tab:hover {
  background-color: var(--primaryColor);
  color: var(--neutral);
  border-bottom: 2px solid var(--primaryColor)
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

/* .card {
  width: 295px;
  height: 381.53px;
  background-image: url('../assets/BannerPhoto.jpeg');
  background-size: cover;
  border-radius: 10px;
  position: relative;
  color: var(--neutral);
} */

.card {
  width: 295px;
  height: 381.53px;
  /* background-image: url('../assets/BannerPhoto.jpeg'); */
  background-size: cover;
  border-radius: 10px;
  position: relative;
  /* Ensure the child element is positioned relative to the card */
}

.cardContent {
  position: absolute;
  width: 280px;
  height: 350px;
  bottom: 20px;
  left: 10px;
  color: var(--neutral);
}

.imageLayout {
  width: 100%;
  height: 100%;
  /* Adjust based on the image height you need */
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* opacity: 50%; */
}

.personInfo {
  /* text-align: left; */
  padding: 10px;
}

.name {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.01em;


  bottom: 50px;
  position: absolute;
}

.skill {
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 11.74px;


  bottom: 30px;
  position: absolute;
}

.city {
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.02em;

  bottom: 10px;
  position: absolute;
}

.coinIcon {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: var(--neutral);
}

/* .heartIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--neutral);
} */

.heartIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  /* color: var(--primaryColor); */
  color: rgba(255, 185, 4, 1);
  /* Add color if needed */
}

.joinUsButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: var(--primaryColor);
  color: var(--neutral);
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.footerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
}

.centerButton {
  display: block;
  margin: 0 auto;
  background-color: rgba(2, 128, 19, 1);

  color: white;
  padding: 10px 20px;
  margin-bottom: 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: Poppins;
  width: 133.61px;
  height: 40.84px;
  padding: 8.42px 16.84px;
  align-items: center;
  gap: 6.73px;
  border-radius: 8.42px;

}

.centerButton:hover {
  background-color: hsl(137, 45%, 60%);
  /* Slightly lighter shade on hover */
  transition: .3s ease;
}

.skillsCount {
  margin-right: 30px;
  padding-top: 10px;
  font-size: 18px;
  font-family: Poppins;
  color: hsl(0, 0, 100, 1);
}