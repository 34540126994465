.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.signup-frame {
  width: 400px;
  padding: 20px;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.claps-image-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.claps-image {
  width: 80px;
  height: 80px;
  border-radius: 25px;
  background: rgba(2, 128, 19, 1);
}

.signup-heading {
  text-align: center;
  margin-bottom: 20px;
}

.signup-title {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: rgba(2, 128, 19, 1);
  margin-bottom: 20px;
}

.signup-subtitle {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(97, 103, 125, 1);
}

/* .input-wrapper {
  position: relative;
  width: 100%;
  height: 58px;
  margin-bottom: 16px;
}

.input-field {
  width: 100%;
  height: 100%;
  padding: 16px 40px 16px 16px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(177, 174, 182, 1);
  border-radius: 8px;
}

.input-field::placeholder {
  color: rgba(107, 107, 107, 1);
}

.input-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: rgba(64, 63, 59, 1);
} */

.input-wrapper {
  position: relative;
  width: 100%;
  height: 58px;
  margin-bottom: 16px;
}

.input-field {
  width: 100%;
  height: 100%;
  padding: 16px 40px 16px 16px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(177, 174, 182, 1);
  border-radius: 8px;
}

.input-field::placeholder {
  color: rgba(107, 107, 107, 1);
}

.input-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: rgba(64, 63, 59, 1);
  cursor: pointer;
}

.checkbox-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 362px;
  height: 24px;
  gap: 0;
  opacity: 1;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
  /* Space between checkbox and label */
}

.checkbox {
  width: var(--CheckboxSize);
  height: var(--CheckboxSize);
  border-radius: var(--CheckboxRadius);
  border: 1px solid rgba(127, 163, 128, 1);
  background: rgba(192, 220, 193, 1);
  opacity: 1;
  /* Adjust opacity as needed */
  cursor: pointer;
  /* Change cursor to pointer */
}

.remember-label {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: rgba(124, 139, 160, 1);
  opacity: 1;
  /* Adjust opacity as needed */
}

.forgot-password-label {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: rgba(46, 125, 49, 1);
  cursor: pointer;
  /* Change cursor to pointer */
  opacity: 1;
  /* Adjust opacity as needed */
}

.or-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.or-line {
  flex-grow: 1;
  height: 1px;
  background: rgba(224, 229, 236, 1);
}

.or-text {
  margin: 0 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: rgba(38, 38, 38, 1);
}

.google-signin-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.google-signin {
  width: 32px;
  height: 32px;
  background: rgba(221, 220, 220, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.google-icon {
  font-size: 20px;

}

.agreement-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.agreement-checkbox {
  margin-right: 10px;
}

.agreement-label {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  line-height: 22px;
}

.create-account-btn {
  width: 100%;
  padding: 16px;
  background: rgba(2, 128, 19, 1);
  color: white;
  font-family: 'Poppins', sans-serif;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.signin-option {
  padding-top: 15px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: rgba(124, 139, 160, 0.6);
}

.signin-link {

  color: rgba(46, 125, 49, 1);
  cursor: pointer;
}