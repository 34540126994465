.whyChooseUsContainer {
  padding: 150px 0px 0px 0px;
}

.whyChooseUsHeader {
  color: rgba(1, 45, 7, 1);
  width: 363px;
  height: 48px;
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
  text-align: center;
  margin: 0 auto;
}

.whyChooseUsSubheader {
  color: rgba(26, 32, 44, 1);
  width: 784px;
  height: 67px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.2px;
  text-align: center;
  margin: 20px auto;
}

.whyChooseUsContent {
  display: flex;
  justify-content: space-between;
  padding: 40px 100px;
}

/* Column 1: Image */
.leftColumn {
  width: 643.63px;
  /* Fixed width */
  height: 477.76px;
  /* Fixed height */
  opacity: 1;
  /* Set to 1 for visibility */
  margin-right: 20px;
  /* Space between columns */
}

.leftColumn img {
  width: 100%;
  /* Make image responsive */
  height: 100%;
  /* Fill the column */
  border-radius: 0px;
  /* Rounded corners */
  object-fit: cover;
  /* Cover the area without stretching */
}

/* .leftColumn .imageStyle {
  width: 637.82px;
  height: 595px;
  top: 3546px;
  left: 100px;
  gap: 0px;
  opacity: 0px;

} */

.rightColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 507.94px;
  height: auto;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 30px;
}

.icon1 {
  color: white;
  width: 50px;
  height: 50px;
  padding: 10px 10px;
  background-color: rgba(2, 128, 19, 1);
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 25px;
}

.textContent {
  display: flex;
  flex-direction: column;
}

.rowHeader {
  /* font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px; */
  color: rgba(26, 32, 44, 1);
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: -0.02em;
  text-align: left;

}

.rowSubheader {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(26, 32, 44, 1);
  margin-top: 5px;

  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;

}