.customerFeedbackSection {
  text-align: center;
  padding: 150px 0px 150px 0px;
}

.feedbackHeader {
  /* width: 1010px;
  height: 48px; */
  margin: 0 auto;
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: rgba(1, 45, 7, 1);

}

.feedbackSubheader {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.2px;
  margin: 20px auto;
  width: 784px;
}

.feedbackCardsContainer {
  /* display: flex;
  justify-content: space-around;
  gap: 0px;
  margin-top: 80px; */
  margin-top: 80px;
  padding-left: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px;
}

.feedbackCard {
  width: 400px;
  height: 300.81px;
  background-color: rgba(246, 247, 249, 1);

  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

}

.customerFeedbackCard {
  display: flex;
  justify-content: space-between;
  /* Align left and right sections */
  align-items: center;
  /* Vertically center the items */
  width: 400px;
  height: 100px;
  /* Adjust height as needed */
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
  /* Optional: for card borders */
  border-radius: 8px;
}

.leftSection {
  display: flex;
  align-items: center;
}

.customerInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.customerPhoto {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  /* Makes the container circular */
  overflow: hidden;
  /* Ensures the image stays within the circle */
  position: relative;
}

.customerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image fills the circle while keeping aspect ratio */
  opacity: 1;
  /* Set the image's opacity to make it visible */
}

.customerNameCity {
  display: flex;
  flex-direction: column;
}

.customerName {
  /*  font-family: Poppins;
  
  font-size: 18px;
  font-weight: 500;
  line-height: 18px; */
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;

  color: rgba(1, 45, 7, 1);
}

.customerCity {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: rgba(79, 86, 101, 1);
}

.rightSection {
  display: flex;
  align-items: center;
}

.customerRating {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 60px;
}

.ratingNumber {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  color: rgba(11, 19, 42, 1);
  margin-right: 5px;
}

.ratingStarIcon {
  color: rgba(255, 185, 4, 1);
}

.customerFeedback {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.2px;
  color: rgba(92, 92, 92, 1);
  margin-top: 20px;
  text-align: left;
}